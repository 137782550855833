@font-face {
  font-family: 'BentonSans';
  src: url('./static/fonts/BentonSans-Book.woff2') format('woff2'),
      url('./static/fonts/BentonSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans';
  src: url('./static/fonts/BentonSans-Medium.woff2') format('woff2'),
      url('./static/fonts/BentonSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'BentonSans';
  src: url('./static/fonts/BentonSans-Bold.woff2') format('woff2'),
      url('./static/fonts/BentonSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  /*
    fonts set to global
    margin: 0;
  */
  font-family: 'BentonSans', sans-serif;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*
    fix for body not being fullsize on screen
    https://stackoverflow.com/a/32319530
  */
  position: absolute;
  width: 100vw;
  /* scroll controll styles */
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

/* hiding the scroll bar */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

body::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.no-scrollbar {
  /* Firefox */
  scrollbar-width: none;
  /* IE 10+ */
  -ms-overflow-style: none;

}

/* WebKit */
.no-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/*
  scroll swap for mobile
*/
@media (max-width: 500px) {
  body {
    overflow: scroll;
    overflow-x: hidden;
  }
}

@media (max-width: 500px) {
  body.m-body-no-scroll {
    overflow: hidden;
    overflow-x: hidden;
  }
}

textarea, select, input, button { outline: none; }
